<!-- =========================================================================================
  File Name: DashboardAnalytics.vue
  Description: Dashboard Analytics
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div id="dashboard-analytics">
    <div class="vx-row w-full">
      <!-- BOOKING OPTIONS -->
      <div class="vx-col w-1/2 mb-10">
        <vx-card title="خيارات الحجز">
            test
        </vx-card>
      </div>
      <!-- // BOOKING OPTIONS // -->
    </div>


  </div>
</template>

<script>

import axios from '@/axios.js'

export default {
  created () {
    console.log("=======created======")
    // this.$http.get(`/test`)
    //     .then((response) => {
    //       console.log("test response", response.data);
    //     })

    //     .catch((error) => { 
    //       console.log("error response", response.data);
    //     })
    const req = new XMLHttpRequest();
    //const baseUrl = 'https://staging.qosoor.sa'
    const baseUrl = 'http://qosoor.test'
    const url = baseUrl+'/agent/api/v1/test'
    // fetch(url, {headers: {
    //   'Accept': 'application/json',
    //   'Authorization': 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImp0aSI6IjljYmJiY2M3MzRlZjFhODU5YTJhMmUyZDczZTExYTljZTNkZGRhNDdjZjBkNWVmMTE3ZGYxZjQ5NmM0OWZmYmIxMDA0MDU3MGJhYWE1NTA2In0.eyJhdWQiOiIxIiwianRpIjoiOWNiYmJjYzczNGVmMWE4NTlhMmEyZTJkNzNlMTFhOWNlM2RkZGE0N2NmMGQ1ZWYxMTdkZjFmNDk2YzQ5ZmZiYjEwMDQwNTcwYmFhYTU1MDYiLCJpYXQiOjE2MDkzNDQwMDYsIm5iZiI6MTYwOTM0NDAwNiwiZXhwIjoxNjQwODgwMDA2LCJzdWIiOiIxIiwic2NvcGVzIjpbXX0.tT5aaQDC1XM_J3e_ZvAuUZgERRA0P31OhQKmVvnaOUl90zZT0HzoWiUxlks8KP0oharqPDMoTkHW6wCYHl9oxI8QB83Dj6vJgbdKd7nUjsfltxWCrjrxVZfXkDXAhKqz8Q4kZx7hGXLIDS_1k-4QGgcjwKmnOz9O-vytH61rkv4_sip_jHERHIiKhevVmaOO7fI9MfCUhgqYAN2sVtxX7LcyREB0NKmKBmUGeqKHR-nCcZGSmOhdn5GUyHHuPiHF5-M0A16ASN45M0FsuyICZZhiIO67rx3MTXRhIcszmQSxGEpC5CbPCHxCllQEx9ebdeF9jlnK8ISXN-CZ40SdYZVMJHKj0Oc3Hgfhxrbe7EwtYVueK0R9lqr1rovGTIV84BP8kDl8CuHKAda7W_g_yd82HcdQQHVGqoGrX0A98WhkdGjk6zQSbBjYEIdrRGi0_uGx5Oc9exQYt-sF_AntFoyzt4gmwEGzH25vw1JppF8Bz1hZgC8zFZ8PSl2b1GFERJVVqqxteCSl5AEmEzB6DmIK99XdBSLKDQvMfNdDWpBfwgazw3VxObfvo8ZmMw2lM6QpEuFG-1RkMPZd6iGsoUt6Wms9-Nr5hlCgLBfRXVEkA27dRB8jlQioTYcYL5fzoOmvyGoCo2351l8HM_Ez7RvxNor7ChTKBGioOB10nFc'
    //   }
    // })
    // .then(response => response.json())
    // .then(json => console.log(json));

    // req.open('get', url);
    // req.onload = () => {
    //   if (req.status === 200){
    //     console.log(req.response)
    //   } 
    // }
    // req.onerror = (e) => {
    //   console.log(e) 
    // }
    // req.send();


    ///////////
    axios.get(url)
    .then(response => {
      console.log(response)
    })
  }
}
</script>

<style lang="scss">
/*! rtl:begin:ignore */
#dashboard-analytics {
  .greet-user{
    position: relative;

    .decore-left{
      position: absolute;
      left:0;
      top: 0;
    }
    .decore-right{
      position: absolute;
      right:0;
      top: 0;
    }
  }

  @media(max-width: 576px) {
    .decore-left, .decore-right{
      width: 140px;
    }
  }
}
/*! rtl:end:ignore */
</style>
